<template>
	<div class="blog-wrapper">
		<div class="container">
			<div class="row title-row align-items-center">
				<div class="col-lg-6 col-md-12 my-auto">
					<p class="title">{{ $t('blog.title') }}</p>
				</div>
				<div class="col-lg-6 d-none d-lg-block text-right">
					<a
						href="https://blog.blockben.com"
						target="_blank"
						class="more-posts"
						>{{ $t('blog.allPosts') }}</a
					>
				</div>
			</div>
		</div>
		<div class="container blogs-container">
			<div class="row blogs-row">
				<Card
					:date="$t('blog.posts.card_1.date')"
					:title="$t('blog.posts.card_1.title')"
					:description="$t('blog.posts.card_1.description')"
					image="/images/sections/blog/blog_01.jpg"
					linkHU="https://blog.blockben.com/hu/b2b-atallas/"
					linkEN="https://blog.blockben.com/b2b-instead-of-b2c/"
				></Card>
				<Card
					:date="$t('blog.posts.card_2.date')"
					:title="$t('blog.posts.card_2.title')"
					:description="$t('blog.posts.card_2.description')"
					image="/images/sections/blog/blog_02.png"
					linkHU="https://blog.blockben.com/hu/gondolatok-a-kripto-piacrol-es-a-bit-termekcsaladrol/"
					linkEN="https://blog.blockben.com/thoughts-on-the-crypto-market-and-the-bit-product-line/"
				></Card>
				<Card
					:date="$t('blog.posts.card_3.date')"
					:title="$t('blog.posts.card_3.title')"
					:description="$t('blog.posts.card_3.description')"
					image="/images/sections/blog/blog_03.png"
					linkHU="https://blog.blockben.com/hu/digitalis-aranytermekek-uj-trend-a-befektetesek-vilagaban/"
					linkEN="https://blog.blockben.com/is-crypto-the-future-of-gold-investment/"
				></Card>
			</div>
		</div>
		<div class="container">
			<div class="row button-row">
				<div class="col-md-12 d-lg-none d-xl-none margin-m">
					<BaseButton
						:text="$t('blog.allPosts')"
						size="large"
						type="primary"
						@clicked="handleButtonClick()"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Blog',
	props: {},
	components: {},
	data: () => ({}),
	created() {},
	computed: {},
	methods: {
		handleButtonClick() {
			window.open('https://blog.blockben.com', '_blank');
		},
	},
};
</script>

<style lang="scss" scoped>
.blog-wrapper {
	padding: 60px 0 75px;
}

.title {
	font-family: 'HKNova-Bold';
	font-size: $headline-l;
	color: $black;
	margin: 0;
}

.text {
	font-size: $text-l;
	color: $black;
}
.bb-arrow-down {
	display: inline-block;
}

.title-row {
	margin-bottom: 60px;
}

.right-arrow {
	display: inline-block;
	transform: rotate(-90deg);
	font-size: $text-l;
}

.more-posts {
	font-family: 'HKNova', sans-serif;
	font-weight: 400;
	color: #4b2eaa !important;
	&:hover {
		color: $link-hover-color !important;
		&:after {
			background: $link-hover-color;
		}
	}

	&:after {
		content: '';
		width: 10px;
		height: 17px;
		background: #4b2eaa;
		display: inline-block;
		mask-image: url('/images/icons/bb-icon_arrow-right.svg');
		margin: 0 0 0 10px;
		top: 2px;
		position: relative;
		transition: $transition-global;
	}
}

.margin-m {
	margin-top: $margin-m;
}
.margin-l-bottom {
	margin-bottom: $margin-l;
}

.card-col {
	margin: auto;
	overflow-x: auto;
	white-space: nowrap;
	height: 610px;
}

.card:not(:last-child) {
	margin-right: 15px;
}

.blogs-container {
	@media (max-width: $breakpoint-md) {
		width: 100%;
		max-width: 100%;
		padding: 0;
	}
}

.blogs-row {
	display: grid;
	grid-template-columns: repeat(3, 350px);
	column-gap: 30px;
	justify-content: center;

	@media (max-width: $breakpoint-lg) {
		grid-template-columns: repeat(3, 300px);
		justify-content: flex-start;
	}

	@media (max-width: $breakpoint-md) {
		overflow-x: scroll;
		padding: 15px 25px;
		column-gap: 20px;
		margin: 0;
	}
}
</style>
