<template>
	<div id="about-us-screen">
		<jumbotron
			:title="$t('aboutUsScreen.welcome.title')"
			:description="$t('aboutUsScreen.welcome.description')"
			:background="'#FFFFFF'"
			imgSrc="/images/screens/about-us/desktop/header_illustration.svg"
			mobileImgSrc="/images/screens/about-us/desktop/header_illustration.svg"
			:imgBackgroundColor="'linear-gradient(0deg, #11efd9, #8477bf)'"
			:buttonText="$t('aboutUsScreen.welcome.buttonText')"
			:mobileHeight="600"
			@buttonClicked="handleJumbotronBtnClick"
		>
		</jumbotron>
		<bridge></bridge>
		<meet-block-ben></meet-block-ben>
		<leaders></leaders>
		<our-drive></our-drive>
		<roadmap></roadmap>
		<up-to-date></up-to-date>
		<history></history>
		<!-- <time-line></time-line> -->
		<Map />
		<permits />
		<legal-information />
		<Partners />
		<blog />
		<contact-us />
	</div>
</template>

<script>
import Blog from '../components/modules/blog/Blog.vue';
import Bridge from '../components/sections/about-us/Bridge.vue';
import ContactUs from '../components/sections/about-us/ContactUs.vue';
import History from '../components/sections/about-us/History.vue';
import Leaders from '../components/sections/about-us/Leaders.vue';
import LegalInformation from '../components/sections/about-us/LegalInformation.vue';
import Map from '../components/sections/about-us/Map.vue';
import MeetBlockBen from '../components/sections/about-us/MeetBlockBen.vue';
import OurDrive from '../components/sections/about-us/OurDrive.vue';
import Permits from '../components/sections/about-us/Permits.vue';
import Partners from '@/components/sections/about-us/Partners';
// import TimeLine from "../components/sections/about-us/TimeLine.vue";
import UpToDate from '../components/sections/about-us/UpToDate.vue';
import Roadmap from '@/components/sections/about-us/Roadmap';
export default {
	name: 'AboutUsScreen',
	props: {},
	components: {
		Partners,
		Bridge,
		MeetBlockBen,
		Leaders,
		OurDrive,
		Roadmap,
		UpToDate,
		History,
		// TimeLine,
		Blog,
		Map,
		Permits,
		LegalInformation,
		ContactUs,
	},
	data: () => ({}),
	created() {},
	computed: {},
	methods: {
		handleJumbotronBtnClick() {
			let el = document.getElementById('about-us-bridge');
			el.scrollIntoView({
				behavior: 'smooth',
			});
		},
	},
};
</script>

<style lang='scss' scoped>
</style>
